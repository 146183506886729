import { Button as ButtonComponent, ButtonProps, cx } from "@vechaiui/react"
import { XMarkIcon } from "@heroicons/react/24/outline"
const Button = (buttonProps: ButtonProps) => {
  const { children, className, ...props } = buttonProps
  return (
    <ButtonComponent
      color="gray"
      variant="solid"
      className={cx(
        className,
        "font-bold border-0 rounded-full shadow-none hover:cursor-pointer hover:duration-100 hover:transition group-hover:ease-in-out text-md"
      )}
      {...props}
    >
      {children}
    </ButtonComponent>
  )
}

export const IconButton = (buttonProps: ButtonProps) => {
  const { children, className, ...props } = buttonProps
  return (
    <button
      {...props}
      className={cx("bg-gray-50 rounded-full p-2 border hover:bg-gray-100", className)}
    >
      {children}
    </button>
  )
}

export default Button
