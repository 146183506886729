import { BlitzPage } from "@blitzjs/next"
import { useRouter } from "next/router"
import Layout from "app/core/layouts/Layout"
import LoginForm from "app/components/auth/LoginForm"

const LoginPage: BlitzPage = () => {
  const router = useRouter()

  if (router.query.next && Array.isArray(router.query.next)) {
    console.log(router.query.next)
  }

  return (
    <div>
      <LoginForm
        // @ts-ignore
        onSuccess={async (_user) => {
          const next = router.query.next ? decodeURIComponent(router.query.next as string) : "/account"
          await router.push(next)
        }}
      />
    </div>
  )
}

LoginPage.redirectAuthenticatedTo = "/account"
LoginPage.getLayout = (page) => <Layout title="Sign in">{page}</Layout>

export default LoginPage
