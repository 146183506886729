import Link from "next/link"
import { useRouter } from "next/router"
import Button from "app/components/Button"

export const LoginForm = () => {
  const router = useRouter()
  const next = router.query.next ? decodeURIComponent(router.query.next as string) : "/"
  return (
    <div className="flex items-center justify-center h-screen max-w-xs mx-auto text-center">
      <div className="flex flex-col items-center space-y-8">
        <span className="inline-flex items-center self-center space-x-2">
          <img className="w-4 h-4" src="/logos/diagram-bw-logo.svg" />
        </span>
        <p className="mt-8 text-lg font-bold">Sign in to Diagram</p>
        <div className="flex space-x-2">
          <Link href={`/api/auth/google?redirectUrl=${next}`} passHref legacyBehavior>
            <Button
              leftIcon={<img className="w-6 h-6 mr-2 rounded-xl" src={"/logos/google-tile.svg"} />}
              variant="light"
            >
              Sign in with Google
            </Button>
          </Link>
        </div>
      </div>

      {/* <h1 className="text-lg font-bold">Login</h1>
      <span className="satoshi">
        Welcome to <strong>designOS</strong>
      </span>
      <Button className="flex items-center space-x-2">
        <Link href="/api/auth/google" passHref>
          <>
            <img className="w-4 h-4" src="https://logo.clearbit.com/google.com" />
            <a href="/api/auth/google"> Sign in with Google</a>
          </>
        </Link>
      </Button> */}
    </div>
  )
}

export default LoginForm
